<script setup lang="ts">
const { src, srcDesk, iframe } = defineProps<{
  src: string;
  srcDesk: string;
  iframe?: boolean;
}>();
const { isMobile } = useDevice();

const videoSource = computed(() => {
  const sources: { src?: string; type: string } = {
    type: "video/mp4",
  };

  if (iframe) {
    sources.src = src;
  } else {
    sources.src = isMobile ? src : srcDesk;
  }

  return { sources };
});
</script>

<template>
  <video v-if="!iframe" controls autoplay>
    <source :src="videoSource.sources.src" type="video/mp4" loading="lazy" />
  </video>
  <iframe
    v-else
    width="100%"
    :src="videoSource.sources.src + '?autoplay=1'"
    title="Iframe video player"
    frameborder="0"
    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
    allowfullscreen
  ></iframe>
</template>

<style lang="scss" scoped>
iframe {
  position: relative;
  height: 100%;
}
</style>
